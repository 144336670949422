<script setup lang="ts">
import { store } from '../store/store'
import { Button } from '@/components/ui/button'
import UserMenu from '@/components/shared/UserMenu.vue'
import { Loader2 } from 'lucide-vue-next';
import { onActivated } from 'vue';
import { useRoute } from 'vue-router';
import { getParam } from '@/lib/utils'
import Features from '@/components/landing/Features.vue';
import Boldable from '@/components/shared/Boldable.vue';
const route = useRoute();

const features = [
    '**Free** for BC Residents with MSP.',
    'Book in Just **2 Minutes.**',
    '**Secure** and **Private** Consultations.',
];

function book() {
    localStorage.removeItem('reschedule');
}

onActivated(() => {
    if (store.loginFlag && store.selectedPatient.pid) {
        store.link = 'who';
    }
    if (route.query.source) {
        localStorage.setItem('source', route.query.source as string);
    } else {
        localStorage.removeItem('source');
    }
})
</script>

<template>
    <div class="w-full flex flex-col h-screen justify-center">
        <div class="flex justify-between gap-10 mb-4 items-center">
            <img v-if="store.clinic.logo" :src="store.clinic.logo" :alt="store.clinic.name" class="pharmacy-logo">
            <div v-else class="text-[22px] text-blue-900 font-bold leading-snug">{{ store.clinic.name }}</div>
            <UserMenu class="ml-auto"></UserMenu>
        </div>
        <h1 class="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-7 lg:mb-12 lg:mt-6 text-gray-900 leading-snug lg:leading-tight">
            Connect with a
            Virtual Doctor in <span class="inline-block">BC, Canada</span></h1>
        <Features :features="features" />
        <div
            class="px-10 w-full py-5 sm:px-0 mt-8 sm:bg-transparent border-t border-gray-200 sm:border-none flex flex-col items-center justify-start grow sm:grow-0">
            <div class="border-gray-300 rounded-lg text-center mt-5 sm:mt-0">
                <div class="font-medium text-[#2F63EB]">First Available Time</div>
                <Boldable variant="extra-bold" v-if="store.clinic.firstAvailableTime" class="mt-1 text-2xl font-regular text-gray-900" :text="store.clinic.firstAvailableTime" />
                <Loader2 v-else class="w-8 h-8 animate-spin mx-auto mt-1" />
            </div>
            <Button size="lg" class="font-bold w-full mt-5 text-lg" as-child @click="book"
                :class="{'opacity-50': !store.link}">
                <RouterLink :to="{name: store.link, params: { domain: getParam(route) }}">
                    Book Now
                    <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
                </RouterLink>
            </Button>
            <div class="flex justify-center text-gray-400 text-sm p-10 mt-auto sm:mt-0">
                Powered By
                <img src="../assets/images/Avee_Logo.svg" alt="avee logo" class="ml-1">
            </div>
        </div>
    </div>
</template>
