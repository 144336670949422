<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { store } from '@/store/store'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios';
import UserMenu from '@/components/shared/UserMenu.vue'
import { clearLocalStorage, convertServerDateToLocalDate, delay, getParam, isToday, isTomorrow } from '@/lib/utils'
import { toast } from '../ui/toast';

const route = useRoute();
const router = useRouter();

async function fetchClinicInfo(subdomain: string, retryCount = 0): Promise<void> {
    const MAX_RETRIES = 4;
    const BASE_DELAY = 1000;

    try {
        const res = await axios.post('/clinicInfo', { subdomain }) as any;
        if (res.status === 'success' && res.data?.cid) {
            if (!store.link) {
                store.link = 'phone';
            }
            storeClinicInfo(res.data);
            setMetaTags(res.data.name);
        } else {
            window.location.href = 'https://avee.health/';
        }
    } catch (error) {
        if (retryCount < MAX_RETRIES) {
            // retry with exponential backoff (e.g. 1, 2, 4, 8 seconds)
            const delayMS = BASE_DELAY * Math.pow(2, retryCount);
            await delay(delayMS);
            return fetchClinicInfo(subdomain, retryCount + 1);
        }

        toast({
            title: 'Failed to fetch clinic info',
            variant: 'destructive',
        });
    }
}

function setMetaTags(cliniName: string) { 
    const title = cliniName + " | Virtual Doctor Booking";
    window.document.title = title;
    const meta1 = document.createElement('meta');
    const meta2 = document.createElement('meta');
    const meta3 = document.createElement('meta');
    meta1.name = "twitter:title";
    meta1.content = title;
    meta2.setAttribute('property', 'og:url');
    meta2.content = window.location.href;
    meta3.setAttribute('property', 'og:title');
    meta3.content = title;
    document.head.appendChild(meta1);
    document.head.appendChild(meta2);
    document.head.appendChild(meta3);
}

function storeClinicInfo (data: any) { 
    store.clinic.id = data.cid;
    store.clinic.name = data.name;
    store.clinic.logo = data.logo;
    store.clinic.type = data.type || 'pharmacy';
    store.clinic.days = data.days;
    if (data.first_available_time) { 
        store.clinic.firstAvailableTime = formatFirstAvailableTime(data.first_available_time, data.first_available_time_more.times.time);
    }
};

const formatFirstAvailableTime = (day: string, time: string) => { 
    const date = convertServerDateToLocalDate(`${day} ${time}:00`);
    
    if (date) {
        let day = '';
        if (isToday(date)) {
            day = 'Today';
        } else if (isTomorrow(date)) {
            day = 'Tomorrow';
        } else {
            day = date.toLocaleString('en-US', { weekday: 'short' });
        }
        const [time, ampm] = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' }).split(' ');

        return `**${day}**, **${time}** ${ampm}`;
    }
    return '';
};

function back() {
    let backPath: string = route.meta.backPath as string;

    if (route.name === 'pay') {
        if (store.clinic.type === 'pharmacy') {
            backPath = 'times';
        } else {
            backPath = 'pharmacies';
        }
    }

    if (store.loginFlag || ['phone', 'verify', 'address'].includes('address')) {
        router.push({ name: backPath, params: { domain: getParam(route) } });
    }
}

const windowWidth = ref(0)

onMounted(async () => {
    windowWidth.value = window.innerWidth
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth
    });

    if (route.name != 'pharmacy') {
        let subdomain: string | string[] = '';
        await router.isReady();
        const domain = route.params.domain ? ('/' + route.params.domain) : '';
        subdomain = window.location.host + domain; 
        // subdomain = 'book32512.avee.health/avcna';

        fetchClinicInfo(subdomain);

        if (route.name === 'verify') {
            router.push({ name: 'home', params: { domain: getParam(route) } });
        }

        if (localStorage.getItem('token')) {

            if (route.name != 'verify') {

                axios.post('/getPatients').then((res: any) => {
                    if (res.status === 'success') {
                        if (res.data.length) {
                            let localInfo: any = localStorage.getItem('appointment');
                            localInfo = JSON.parse(localInfo);
                            if (localInfo) {
                                store.appointment = localInfo;
                                let who = res.data.filter((x) => {
                                    return x.pid === store.appointment.pid;
                                });
                                if (who.length) {
                                    who = who[0];
                                } else {
                                    clearLocalStorage();
                                    who = res.data[0];
                                }
                                store.setSelectedPatient(who);
                                store.phone = who.mobile;
                            } else {
                                let who: any = [];
                                if (localStorage.getItem('reschedule')) {
                                    who = res.data.filter((x) => {
                                        return x.pid === localStorage.getItem('reschedule');
                                    })[0];
                                } else {
                                    who = res.data[0];
                                }
                                store.setSelectedPatient(who);
                                store.phone = who.mobile;
                            }
                            store.members = res.data
                            store.login()
                            store.link = 'who'
                            if (route.name === 'phone') {
                                router.push({ name: 'who', params: { domain: getParam(route) } });                           
                            }
                        } else {
                            store.link = 'login'
                            router.push({ name: 'login', params: { domain: getParam(route) } });
                        }
                    } else if (res.status === 'error' && res.code === 440) {
                        localStorage.removeItem('token');
                        clearLocalStorage();
                        store.link = 'phone';
                        handleRedirect();
                    }
                });

            }

        } else {
            handleRedirect();
        }

    }
});

function handleRedirect() {
    if (route.name === 'manage_appointment') {
        store.redirect_page.fullPath = route.fullPath;
        store.redirect_page.name = route.name;
        store.redirect_page.query = route.query;
        router.push({ name: 'phone', params: { domain: getParam(route) } });
    } else {
        router.push({ name: 'home', params: { domain: getParam(route) } });
    }
}
</script>

<template>
    <header class="pb-10 flex mb-4 items-center z-50 relative" :class="{'relative': windowWidth < 1200 }">
        <i v-if="windowWidth < 1200 && route.name != 'who' && route.name != 'login'" class="isax isax-arrow-left text-3xl cursor-pointer" @click="back"></i>
        <div v-else>
            <img v-if="store.clinic.logo" :src="store.clinic.logo" :alt="store.clinic.name" class="pharmacy-logo">
            <div v-else class="text-[22px] text-blue-900 font-bold leading-snug z-50">{{ store.clinic.name }}</div>
        </div>
        <UserMenu class="ml-auto"></UserMenu>
    </header>
</template>
