import axios from 'axios';
import { type ClassValue, clsx } from 'clsx'
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getParam(route?: RouteLocationNormalizedLoaded): string {
  if (route && route.params && 'domain' in route.params) {
    return route.params.domain as string;
  }
  return '';
}

export function clearLocalStorage() {
    localStorage.removeItem('doctor');
    localStorage.removeItem('appointment');
    localStorage.removeItem('reschedule');
}

const formatTimePart = (part: number): string => {
  return part.toString().padStart(2, '0');
};

export function bucketTime(time: string): string {
  const [hour, minute] = time.split(':').map(Number);
  const minuteBucket = minute - (minute % 15);
  return `${formatTimePart(hour)}:${formatTimePart(minuteBucket)}`;
}

/**
 * Delay for a given number of milliseconds
 * @param ms - The number of milliseconds to delay
 * @returns A promise that resolves after the delay
 */
export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function updateAxiosAuthHeader(token: string) {
  axios.defaults.headers['authorization'] = `Bearer ${token}`;
}

/**
 * Convert a date string from the server to a local date
 * @param dateStr - The date string to convert in the format "yyyy-MM-dd HH:mm:ss"
 * @returns The local date
 */
export function convertServerDateToLocalDate(dateStr: string): Date {
    const date = DateTime.fromFormat(dateStr, "yyyy-MM-dd HH:mm:ss", {
        zone: "America/Vancouver",
    })
    return date.toJSDate()
}

/**
 * Given the appointment ID, returns the params which should be sent to intercom
 * for that appointment.
 * 
 * @param appointmentId - The appointment ID
 * @returns Params the should be attached to the user object in intercom
 */
export function addIntercomAppointmentParams(appointmentId: Nullable<string>): {
  app_id: Nullable<string>,
  panel_link: Nullable<string>,
} {
  const mustResetAppointmentParams = appointmentId === null || appointmentId === '';

  if (mustResetAppointmentParams) {
    // to reset the intercom params, we must set them to null
    return {
      app_id: null,
      panel_link: null,
    }
  }
  
  return {
      app_id: appointmentId,
      panel_link: `https://aveecloud.com/admn/appointment_report?appID=${appointmentId}`,
  }
}

export function isToday(date: Date): boolean {
  return date.toLocaleString('en-US', { weekday: 'short' }) === 
         new Date().toLocaleString('en-US', { weekday: 'short' });
}

export function isTomorrow(date: Date): boolean {
  return date.toLocaleString('en-US', { weekday: 'short' }) === 
         new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleString('en-US', { weekday: 'short' });
}
