<script setup lang="ts">
import { Card } from '@/components/ui/card';
import { store } from '@/store/store';
// import { Button } from '@/components/ui/button'
// import {
//     Collapsible,
//     CollapsibleContent,
//     CollapsibleTrigger,
// } from '@/components/ui/collapsible'
import { ref, watch } from 'vue'
import axios from 'axios'
import { Loader2 } from 'lucide-vue-next';
import { getParam } from '@/lib/utils';
import { useRoute } from 'vue-router';
import { toast } from '@/components/ui/toast/use-toast';

interface Doctor {
    id: string;
    image: string;
    name: string;
    days: any;
    price: string;
    currency: string;
    languages: string;
}

const route = useRoute();
const pageLoader = ref(true);
const doctorsLoader = ref(false);
const activeCard = ref(false);
const doctors = ref<Doctor[]>([]);
const fetchDoctors = ref(false);
const doctorList = ref<HTMLElement | null>(null);
// const isOpen = ref(new Array(doctors.value.length).fill(false))

// function close(i: number) {
//     isOpen.value[i] = false
// }

function chooseDoctor(id: string) {
    if (store.selectedDoctor.id === id) {
        return;
    }

    const selectedDoctor = doctors.value.find((doctor: Doctor) => doctor.id === id);

    if (selectedDoctor) {
        storeSelectedDoctor(selectedDoctor);
    }
}

function storeSelectedDoctor(doctor: Doctor) {
    Object.assign(store.selectedDoctor, {
        id: doctor.id,
        image: doctor.image,
        name: doctor.name,
        days: doctor.days,
        price: { price: doctor.price, currency: doctor.currency },
        first_available: false
    });
    store.appointment.doctorID = doctor.id;
    store.appointment.day = '';
    store.appointment.time = '';

    localStorage.setItem('appointment', JSON.stringify(store.appointment));
    localStorage.setItem('doctor', JSON.stringify(store.selectedDoctor));
 }

function getDoctors() {
    if (!doctorsLoader.value && !fetchDoctors.value) { 
        doctorsLoader.value = true;
        activeCard.value = true;
        axios.post('/doctors', { cid: store.clinic.id }).then((res: any) => {
            if (res.status === 'success') {
                fetchDoctors.value = true;
                doctors.value = res.data;
                
                setTimeout(() => {
                    doctorList.value?.scrollIntoView({ behavior: 'smooth' });
                }, 1);
            } else {
                handleError(res.msg);
            }
        }).catch(() => {
            handleError('Something went wrong. Please try again later.');
        }).finally(() => {
            doctorsLoader.value = false;
        });
    }
}

const handleError = (msg: string) => {
    toast({
        title: msg,
        variant: 'destructive'
    });
}

const DEFAULT_DOCTOR_STATE = {
    id: '',
    image: '',
    name: '',
    days: [],
    price: { price: '', currency: '' },
    first_available: true
} as const;

function clearDoctorSelection() {
    Object.assign(store.selectedDoctor, DEFAULT_DOCTOR_STATE);
    store.appointment.doctorID = '';
    store.appointment.day = '';
    store.appointment.time = '';
    
    localStorage.setItem('appointment', JSON.stringify(store.appointment));
    localStorage.removeItem('doctor');
}

watch(() => store.clinic.id, function (id) {
    if (id) {
        pageLoader.value = false;
    }
}, { immediate: true });
</script>

<template>
    <h2 class="title">Would you like to choose a specific doctor?</h2>
    <Loader2 v-if="pageLoader" class="w-10 h-10 mx-auto mt-10 animate-spin" />
    <div v-else class="mt-10">
        <Card class="mb-3" as-child>
            <RouterLink :to="{ name: 'times', params: { domain: getParam(route) } }" class="block p-3" @click="clearDoctorSelection">
                <div class="font-medium text-gray-950">No, any available doctor</div>
                <div class="mt-1 text-sm text-gray-400">See all available times.</div>
            </RouterLink>
        </Card>
        <Card class="mb-6 p-3 cursor-pointer" :class="{'border-2 border-primary bg-cyan-50': activeCard}" @click="getDoctors">
            <div class="font-medium text-gray-950" :class="{'font-semibold': activeCard}">Yes, I want to choose</div>
            <div class="mt-1 text-sm text-gray-400">See the list of our qualified doctors.</div>
        </Card>
        <Loader2 v-if="doctorsLoader" class="w-10 h-10 mx-auto mt-10 animate-spin" />
        <a name="doctorList" class="block h-0.5 w-full" ref="doctorList"></a>
        <div v-if="doctors.length" class="font-bold text-[28px] mb-7 mt-2">Select your doctor</div>
        <div v-else-if="!doctorsLoader && fetchDoctors" class="text-sm text-gray-400 mt-10">No doctors found.</div>
        <Card v-for="doctor in doctors" :key="doctor.id" as-child class="mb-3">
            <RouterLink :to="{ name: 'times', query: {doctorName: doctor.name}, params: { domain: getParam(route) } }" class="block p-3" @click="chooseDoctor(doctor.id)">
            <!-- <Collapsible v-model:open="isOpen[i]"> -->
                <div class="flex items-center">
                    <!-- <div> -->
                        <img :src="doctor.image || '/default.webp'" :alt="doctor.name" width="60" height="60" class="h-[60px] rounded-full">
                    <!-- </div> -->
                    <div class="ml-4">
                        <!-- <RouterLink :to="{ name: 'times', params: { domain: getParam(route) } }" @click="chooseDoctor(doctor.id)"> -->
                            <div class="font-medium text-gray-900">{{ doctor.name }}</div>
                        <!-- </RouterLink> -->
                        <div class="text-sm text-gray-400 mt-1">{{ doctor.languages }}</div>
                        <!-- <CollapsibleTrigger v-if="doctor.description || doctor.linkedin || doctor.college || doctor.comments?.length" class="inline-flex items-center text-xs mt-1 cursor-pointer py-0 w-1">
                            <i class="isax isax-arrow-down-1 mr-1"></i>
                            More
                        </CollapsibleTrigger> -->
                    </div>
                    <div class="shrink-0 ml-auto p-1 flex items-center">
                        <i class="isax isax-arrow-right-1 text-xl text-gray-400"></i>
                    </div>
                </div>
                <!-- <CollapsibleContent class="text-sm mt-2.5">
                    <div class="text-gray-800">{{ doctor.description }}</div>
                    <div class="flex gap-2.5 mt-2.5">
                        <a v-if="doctor.linkedin" :href="doctor.linkedin" target="_blank" class="text-gray-800 font-medium py-1.5 px-3">
                            <i class="isax isax-export-3 mr-1 text-xs"></i>
                            <span class="underline">linkedin</span>
                        </a>
                        <a v-if="doctor.college" :href="doctor.college" target="_blank" class="text-gray-800 font-medium py-1.5 px-3">
                            <i class="isax isax-export-3 mr-1 text-xs"></i>
                            <span class="underline">College profile</span>
                        </a>
                    </div>
                    <div v-if="doctor.comments?.length" class="border-b my-2.5"></div>
                    <div v-for="comment in doctor.comments" :key="comment.id" class="mb-2.5 text-gray-700">
                        <div class="text-[13px] font-semibold">{{ comment.name }}</div>
                        <div class="mt-0.5">{{ comment.text }}</div>
                    </div>
                    <Button variant="ghost" class="block mx-auto mt-2.5" @click="close(i)">
                        <i class="isax isax-arrow-up-2"></i>
                        Close
                    </Button>
                </CollapsibleContent> -->
            <!-- </Collapsible> -->
            </RouterLink>
        </Card>
    </div>
</template>

<style scoped>
h3.flex {
    display: inline-flex;
}
</style>
