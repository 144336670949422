<script lang="ts">
  export default {
    name: 'Verify'
  }
</script>

<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted } from 'vue';
import {
    PinInput,
    PinInputGroup,
    PinInputInput,
} from '@/components/ui/pin-input';
import { Button } from '@/components/ui/button';    
import { store } from '@/store/store';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from '@/components/ui/toast/use-toast';
import axios from 'axios';
import { Loader2 } from 'lucide-vue-next';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { getParam } from '@/lib/utils';

// Constants
const PIN_LENGTH = 5;
const RESEND_COOLDOWN = 60;

// Composables
const { toast } = useToast();
const recaptchaInstance = useReCaptcha();
const route = useRoute();
const router = useRouter();

// State
const token = ref('');
const counter = ref(0);
const loader = ref(false);
const code = ref<string[]>([]);
const prevOTP = ref('');
const userPhone = ref(formatPhoneNumber(store.phone));
const resendFlag = ref(true);

// Utils
function formatPhoneNumber(phone: string) {
    if (!phone) {
        return '';
    }
    const digits = phone.replace(/\D/g, '');
    const parts = digits.match(/.{1,3}/g);
    return parts ? `+1 (${parts[0]}) ${parts[1]}-${parts[2]}${parts[3]}` : '';
}

// Recaptcha
const getRecaptchaToken = async () => {
    await recaptchaInstance?.recaptchaLoaded();
    return await recaptchaInstance?.executeRecaptcha("submitOTP") || '';
};

// Auth handlers
async function handleAuthSuccess(res: any) {
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('phone', store.phone);
    axios.defaults.headers['authorization'] = 'Bearer ' + res.data.token;
  
    const patients = await axios.post('/getPatients');
  
    if (patients.data.length) {
        store.members = patients.data;
        store.setSelectedPatient(patients.data[0]);
        store.login();
    
        if (store.redirect_page.fullPath) {
            const page = store.redirect_page;
            store.redirect_page = { fullPath: '', name: '', query: {} };
            router.push({ name: page.name, query: page.query, params: { domain: getParam(route) } });
            return;
        }
        
        setTimeout(() => {
            loader.value = false;
            router.push({ name: 'who', params: { domain: getParam(route) } });
        }, 300);
    } else {
        store.link = 'login';
        setTimeout(() => {
            loader.value = false;
            router.push({ name: 'login', params: { domain: getParam(route) } });
        }, 300);
    }
}

async function submitOTP() {
    if (code.value.length !== PIN_LENGTH || code.value.includes('') || loader.value) {
        return;
    }
  
    const otp = code.value.join('');
    if (otp === prevOTP.value) {
        return;
    }
  
    prevOTP.value = otp;
    if (!token.value) {
        token.value = await getRecaptchaToken();
    }

    loader.value = true;
    axios.post('/auth', {
        code: otp,
        uniq: store.uniq,
        phone: store.phone,
        role: 'patient',
        token: token.value
    }).then(async (res: any) => {
        if (res.status === 'success') {
            handleAuthSuccess(res);
        } else {
            if (res.code === 406) {
                toast({
                    title: 'Your connection didn’t pass our security verification process. Please try again in a few minutes. If the issue persists, contact support for assistance.',
                    variant: 'destructive'
                });
                token.value = await getRecaptchaToken();
                prevOTP.value = '';
            } else {
                toast({
                    title: res.msg,
                    variant: 'destructive'
                });
            }
            loader.value = false;
        }
    }).catch(() => {
        loader.value = false;
        toast({
            title: 'An error occurred',
            variant: 'destructive'
        });
    });
}

function resendOTP() {
    if (!resendFlag.value) {
        return;
    }
  
    resendFlag.value = false;
    axios.post('/checkPhone', { phone: store.phone })
        .then((res: any) => {
            if (res.status === 'success') {
                store.uniq = res.data.uniq;
                toast({ title: 'A code was sent to your phone.' });
            
                const endTime = Date.now() + (RESEND_COOLDOWN * 1000);
                const timer = setInterval(() => {
                    const remaining = Math.ceil((endTime - Date.now()) / 1000);
                    counter.value = remaining;
                    
                    if (remaining <= 0) {
                        clearInterval(timer);
                        resendFlag.value = true;
                        counter.value = 0;
                    }
                }, 500);
            } else {
                resendFlag.value = true;
                toast({
                    title: res.msg,
                    variant: 'destructive'
                });
            }
        })
        .catch(() => {
            resendFlag.value = true;
            toast({
                title: 'Failed to resend code',
                variant: 'destructive'
            });
        });
}

// Initial setup
if (store.loginFlag) {
    router.push({ name: 'home', params: { domain: getParam(route) } });
}

getRecaptchaToken().then(res => {
    token.value = res;
});

onMounted(() => {
    if (recaptchaInstance && recaptchaInstance.instance.value) {
        recaptchaInstance.instance.value.showBadge();
    }
});

onUnmounted(() => {
    if (recaptchaInstance && recaptchaInstance.instance.value) {
        recaptchaInstance.instance.value.hideBadge();
    }
});

// Watchers
watch(code, (val) => {
    if (val.length === PIN_LENGTH) {
        submitOTP();
    }
});
</script>

<template>
    <h2 class="title">Enter Verification Code</h2>
    <div class="mt-1 text-gray-600">Enter the 5-digit code sent to {{userPhone}}</div>
    <div class="mt-7">
        <PinInput id="pin-input" v-model="code" class="justify-center" otp type="number">
            <PinInputGroup>
                <PinInputInput
                v-for="(id, index) in 5"
                :key="id"
                :index="index"
                type="number"
                />
            </PinInputGroup>
        </PinInput>
    </div>
    <div class="mt-7 font-medium">
        Didn't get the code? 
        <span class="text-secondary font-bold" :class="{'cursor-not-allowed': !resendFlag, 'cursor-pointer': resendFlag}" @click="resendOTP">Resend</span>
        <div v-if="counter" class="text-gray-700 text-sm mt-2">You can request a new code after {{ counter + (counter > 1 ? ' seconds' : ' second') }}.</div>
    </div>
    <Button size="lg" class="g-recaptcha font-bold w-full mt-7 text-lg" :disabled="loader" :class="{'opacity-30 cursor-not-allowed': code.length != 5 || code.includes('')}" @click="submitOTP">
        <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
        Log in
        <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
    </Button>
</template>
