<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Loader2 } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { store } from '@/store/store'
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getParam } from '@/lib/utils'
import { useDateValidation } from '@/lib/useDateValidation'

const route = useRoute();
const router = useRouter();

const name = ref('')
const family = ref('')
const gender = ref('')
const { 
    year, 
    month, 
    day, 
    daysInMonth, 
    isFutureDate, 
    yearRange 
} = useDateValidation()
const loader = ref(false)

const formSchema = toTypedSchema(z.object({
    name: z.string().min(3, {message: 'Minimum 3 characters.'}),
    family: z.string().min(3, {message: 'Minimum 3 characters.'}),
    gender: z.string(),
    year: z.string(),
    month: z.string(),
    day: z.string()
        .refine((val) => {
            if (!year.value || !month.value || !val) {
                return true;
            }
            const maxDays = daysInMonth.value;
            return parseInt(val) <= maxDays;
        }, { message: "Invalid day for selected month" })
        .refine((val) => {
            if (!year.value || !month.value || !val) {
                return true;
            }
            return !isFutureDate(year.value, month.value, val);
        }, { message: "Date cannot be in the future" })
}))

const { handleSubmit } = useForm({
  validationSchema: formSchema,
})

const onSubmit = handleSubmit(() => {
    if (loader.value) {
        return;
    }

    loader.value = true;
    storeNewPatient()

    setTimeout(function () {
        loader.value = false;
        router.push({ name: 'address', params: { domain: getParam(route) } });
    }, 300);
});

function storeNewPatient() {
    store.newPatient.name = name.value;
    store.newPatient.family = family.value;
    store.newPatient.gender = gender.value;
    store.newPatient.birthday = year.value + '-' + month.value + '-' + day.value;
}
</script>

<template>
    <h2 class="title">Talk about yourself</h2>
    <div class="mt-1 text-gray-600">We need your profile data to schedule a doctor meeting for you.</div>
    <form @submit="onSubmit" class="grid gap-7 mt-10">
        <FormField v-slot="{ componentField }" name="name">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="name">First name</Label>
                        <Input id="name" type="text" v-model:model-value="name" v-bind="componentField"/>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="family">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="family">Last name</Label>
                        <Input id="family" type="text" v-model:model-value="family" v-bind="componentField"/>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="gender" :validate-on-model-update="true">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="gender">Sex assigned at birth</Label>
                        <Select id="gender" v-model:model-value="gender" v-bind="componentField">
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="M">Male</SelectItem>
                                <SelectItem value="F">Female</SelectItem>
                                <SelectItem value="I">Intersex</SelectItem>
                                <SelectItem value="Not">Prefer not to say</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <div class="grid gap-1.5">
            <Label>Date of birth</Label>
            <div class="date-container">
                <div class="w-[30%]">
                    <FormField v-slot="{ componentField }" name="year" :validate-on-model-update="true">
                        <FormItem v-auto-animate>
                            <FormControl>
                                <Select id="year" v-model:model-value="year" v-bind="componentField">
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem 
                                            v-for="year in yearRange" 
                                            :key="year"
                                            :value="String(year)"
                                        >
                                            {{ year }}
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
                <div class="w-[40%]">
                    <FormField v-slot="{ componentField }" name="month" :validate-on-model-update="true">
                        <FormItem v-auto-animate>
                            <FormControl>
                                <Select id="month" v-model:model-value="month" v-bind="componentField">
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem 
                                            v-for="n in 12" 
                                            :value="String(n).padStart(2, '0')"
                                            :key="n"
                                        >
                                            {{ String(n).padStart(2, '0') + ' - ' + store.months[n-1] }}
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
                <div class="w-[30%]">
                    <FormField v-slot="{ componentField }" name="day" :validate-on-model-update="true">
                        <FormItem v-auto-animate>
                            <FormControl>
                                <Select id="day" v-model:model-value="day" v-bind="componentField" class="w-full">
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem v-for="n in daysInMonth" :value="String(n).padStart(2, '0')">{{ String(n).padStart(2, '0') }}</SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
            </div>
        </div>
        <Button type="submit" size="lg" class="font-bold w-full mt-4 text-lg" :disabled="loader || !name || !family || !gender || !year || !month || !day">
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Continue
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
    </form>
</template>
