import { ref, onMounted, onUnmounted, watch } from 'vue'

const provinceBounds = {
    AB: {
        // Alberta
        southwest: { lat: 49.0, lng: -120.0 },
        northeast: { lat: 60.0, lng: -110.0 },
    },
    BC: {
        // British Columbia
        southwest: { lat: 48.3, lng: -139.0 },
        northeast: { lat: 60.0, lng: -114.0 },
    },
    MB: {
        // Manitoba
        southwest: { lat: 49.0, lng: -102.0 },
        northeast: { lat: 60.0, lng: -89.0 },
    },
    NB: {
        // New Brunswick
        southwest: { lat: 44.6, lng: -69.0 },
        northeast: { lat: 48.0, lng: -63.8 },
    },
    NL: {
        // Newfoundland and Labrador
        southwest: { lat: 46.6, lng: -67.8 },
        northeast: { lat: 60.0, lng: -52.6 },
    },
    NT: {
        // Northwest Territories
        southwest: { lat: 60.0, lng: -137.0 },
        northeast: { lat: 78.0, lng: -102.0 },
    },
    NS: {
        // Nova Scotia
        southwest: { lat: 43.4, lng: -66.3 },
        northeast: { lat: 47.0, lng: -59.7 },
    },
    NU: {
        // Nunavut
        southwest: { lat: 51.7, lng: -102.0 },
        northeast: { lat: 83.1, lng: -60.0 },
    },
    ON: {
        // Ontario
        southwest: { lat: 41.7, lng: -95.2 },
        northeast: { lat: 56.9, lng: -74.4 },
    },
    PE: {
        // Prince Edward Island
        southwest: { lat: 45.9, lng: -64.4 },
        northeast: { lat: 47.1, lng: -62.0 },
    },
    QC: {
        // Quebec
        southwest: { lat: 44.9, lng: -79.8 },
        northeast: { lat: 62.6, lng: -57.1 },
    },
    SK: {
        // Saskatchewan
        southwest: { lat: 49.0, lng: -110.0 },
        northeast: { lat: 60.0, lng: -101.3 },
    },
    YT: {
        // Yukon
        southwest: { lat: 60.0, lng: -141.0 },
        northeast: { lat: 69.7, lng: -124.0 },
    },
}

/**
 * Safely loads the Google Maps JavaScript API and returns a promise that resolves when the API is loaded.
 * 
 * @returns A promise that resolves when the API is loaded.
 */
function loadGoogleMaps() {
    const MAX_RETRIES = 3
    const CALLBACK_NAME = "aveeInitializeGoogleMaps"
    const API_KEY = import.meta.env.VITE_PLACES_API_KEY
    const SCRIPT_ID = "avee-google-maps-script"
    let retryCounter = 0

    return new Promise<void>((resolve, reject) => {
        const isGoogleMapsLoaded =
            typeof google !== "undefined" && google.maps && google.maps.places
        
        if (isGoogleMapsLoaded) {
            resolve()
            return
        }

        try {
            window[CALLBACK_NAME] = () => {
                resolve()
            }

            const existingScript = document.getElementById(SCRIPT_ID) as HTMLScriptElement | null
            const script = existingScript || document.createElement("script")
            script.async = true
            script.id = SCRIPT_ID
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&loading=async&callback=${CALLBACK_NAME}`
            document.head.appendChild(script)
        } catch (error) {
            if (retryCounter < MAX_RETRIES) {
                retryCounter++
                setTimeout(
                    () => loadGoogleMaps().then(resolve).catch(reject),
                    500 * retryCounter
                )
            } else {
                reject(error)
            }
        }
    })
}


export function useGooglePlaces() {
    const country = ref('ca')
    const province = ref('')
    const city = ref('')
    const postal_code = ref('')
    const address = ref('')
    
    let autocomplete: google.maps.places.Autocomplete

    function getAddress() {
        const input: HTMLInputElement | null = document.getElementById('address') as HTMLInputElement
        if (!input) return

        loadGoogleMaps().then(() => {
            let options: google.maps.places.AutocompleteOptions = {
                types: ["address"],
                componentRestrictions: {
                    country: country.value,
                },
                fields: ["address_components", "formatted_address"],
            }

            // if province has been selected, restrict search boundaries to the province
            if (country.value === "ca" && province.value) {
                const selectedProvinceBounds =
                    provinceBounds[province.value.toUpperCase()]

                if (!!selectedProvinceBounds) {
                    options.bounds = new google.maps.LatLngBounds(
                        selectedProvinceBounds.southwest,
                        selectedProvinceBounds.northeast
                    )
                    options.strictBounds = true
                }
            }

            autocomplete = new google.maps.places.Autocomplete(input, options)

            google.maps.event.addListener(autocomplete, "place_changed", () => {
                const place = autocomplete.getPlace()

                address.value = place.formatted_address || ""

                place.address_components?.forEach((component) => {
                    component.types.forEach((type) => {
                        if (
                            type == "locality" ||
                            type == "postal_town" ||
                            type == "administrative_area_level_2"
                        ) {
                            city.value = component.long_name
                        } else if (type == "postal_code") {
                            postal_code.value = component.long_name
                        } else if (
                            type == "administrative_area_level_1" &&
                            country.value == "ca"
                        ) {
                            province.value = component.short_name
                        }
                    })
                })
            })
        })
    }

    watch(province, (val) => {
        if (val == 'US State') {
            country.value = 'us'
        } else if (val == 'Outside Canada and USA') {
            country.value = ''
        } else {
            country.value = 'ca'
        }
        getAddress()
    })

    onMounted(() => {
        getAddress()
    })

    onUnmounted(() => {
        if (autocomplete) {
            google.maps.event.clearInstanceListeners(autocomplete)
        }
    })

    return {
        country,
        province,
        city,
        postal_code,
        address
    }
} 
