<script setup lang="ts">
import { Card } from '@/components/ui/card'
import { store } from '@/store/store'
import { watch, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Loader2 } from 'lucide-vue-next';
import { getParam } from '@/lib/utils';
import SupportChat from '@/components/shared/SupportChat.vue';

const route = useRoute();
const router = useRouter();
const loader = ref(true);

function choosePatient (id: string) {

    storeSelectedPatient(id);
    storeSource();
    
    localStorage.setItem('appointment', JSON.stringify(store.appointment));
    localStorage.removeItem('reschedule');
        
    router.push({name: 'details', params: { domain: getParam(route) }})

}

function storeSelectedPatient(id: string) {
    const selectedMember = store.members.find((member) => member.pid === id);
    store.setSelectedPatient(selectedMember);
    store.appointment.pid = selectedMember.pid;
}

function storeSource() {
    const source = localStorage.getItem('source');
    if (source) {
        store.appointment.source = source;
    }
}

watch(() => store.members, (members) => {
    if (members.length) {
        loader.value = false;
    }
}, { immediate: true });
</script>

<template>
    <h2 class="title">Who is this visit for?</h2>
    <p class="mt-1 text-gray-600">You can talk to the provider on behalf of someone in your care, as long as you are their legal guardian or you have power of attorney over their medical decisions.</p>
    <span class="inline-block mt-5 text-gray-600">Need to edit? <SupportChat class="no-underline" label="Click here to chat with support" /></span>
        <Loader2 v-if="loader" class="w-10 h-10 mx-auto mt-10 animate-spin " />
        <div v-else class="mt-10 grid gap-3">
            <Card v-for="patient in store.members" class="cursor-pointer" @click="choosePatient(patient.pid)">
                <div class="flex justify-between items-center p-3 min-h-13">
                    <div class="grid gap-1">
                        <div class="font-medium text-gray-900">{{ patient.name + ' ' + patient.family }}</div>
                        <div class="text-sm text-gray-400 capitalize" v-if="patient.relationship">{{ patient.relationship }}</div>
                    </div>
                    <i class="isax isax-arrow-right-1 text-xl shrink-0 text-gray-400"></i>
                </div>
            </Card>
            <RouterLink :to="{name: 'add', params: { domain: getParam(route) }}" class="flex items-center p-3 border border-dashed rounded-lg border-gray-300 font-medium text-secondary min-h-13 mb-10">
                <i class="isax isax-add text-xl mr-2.5"></i>
                Someone else in my care
            </RouterLink>
        </div>
</template>
