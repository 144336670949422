<script lang="ts" setup>
import { cn } from '@/lib/utils';
import { show } from '@intercom/messenger-js-sdk';

const { label, className } = withDefaults(defineProps<{
  label?: string
  className?: string,
}>(), {
  label: 'Chat with support',
});

const mergedClassName = cn('text-sm ml-1 underline text-secondary cursor-pointer', className);

function openChatWidget() {
    show();
}
</script>

<template>
  <span @click="openChatWidget" :class="mergedClassName">{{ label }}</span>
</template>
