<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { PinInputInput, type PinInputInputProps, useForwardProps } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps<PinInputInputProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <PinInputInput v-bind="forwardedProps" :class="cn('relative text-center rounded-none focus:outline-none focus:ring-1 focus:ring-ring focus:relative focus:z-10 flex h-13 w-13 items-center justify-center border-y border-r border-input text-sm transition-all first:rounded-l-xl first:border-l last:rounded-r-xl', props.class)" />
</template>
