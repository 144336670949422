<script setup lang="ts">
import { Card } from '@/components/ui/card'
import { store } from '@/store/store';
import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from '@/components/ui/toast/use-toast';
import { SearchIcon, Loader } from 'lucide-vue-next';
import { clearLocalStorage, getParam } from '@/lib/utils'
import { refDebounced, debouncedRef } from '@vueuse/core'

const { toast } = useToast()
const route = useRoute();
const router = useRouter();

const pharmacies = ref([] as any);
const clinicAvailable = ref(false);
const patientAvailable = ref(false);
const noPharmacies = ref(false);
const searchQuery = ref(route.query.keyword?.toString() || '')
const debouncedSearchQuery = refDebounced(searchQuery, 500)
const isSearching = ref(false)
const isUpdatingAppointment = ref(false);
const isSearchFieldDirty = ref(false);
const searchField = ref<HTMLElement | null>(null);

function choosePharmacy (index: number) {
    store.appointment.preferredPharmacy = pharmacies.value[index];
    localStorage.setItem('appointment', JSON.stringify(store.appointment));
    if (localStorage.getItem('reschedule')) {
        if (isUpdatingAppointment.value) {
            return;
        }

        const clinicId = store.appointment.clinicID ? store.appointment.clinicID : store.clinic.id;

        const values = {
            doctorID: store.appointment.doctorID,
            time: store.appointment.time,
            day: store.appointment.day,
            appID: store.app.appID,
            pid: store.app.pid,
            cid: clinicId,
            preferredPharmacy: store.appointment.preferredPharmacy,
            save: true
        }
        isUpdatingAppointment.value = true;
        axios.post('/updtAppointment', values).then((res: any) => {
            if (res.status == 'success') {
                clearLocalStorage();
                router.replace({ name: 'success', query: { appID: res.data.appID } });
            } else {
                toast({
                    title: res.msg,
                    variant: 'destructive'
                })
            }
        }).finally(() => {
            isUpdatingAppointment.value = false;
        });
    } else {
        const query = { doctorName: store.selectedDoctor.name, day: store.appointment.day, cid: store.appointment.cid };
        router.push({ name: 'pay', query, params: { domain: getParam(route) } });
    }
}

function getPharmacies(query: string) {
    noPharmacies.value = false;
    isSearching.value = true;
    axios.post('/getAssignedPharmaciesToClinic', {
        cid: store.clinic.id,
        pid: store.appointment.pid || store.selectedPatient.pid,
        keyword: query
    })
        .then((res: any) => {
            if (res.status == 'success') {
                router.push({ query: { ...route.query, keyword: query } });
                const pharmaciesListWasEmpty = pharmacies.value?.length === 0;
                pharmacies.value = res.data;

                if (pharmaciesListWasEmpty) {
                    setTimeout(() => {
                        searchField.value?.scrollIntoView({ behavior: 'smooth' });
                    }, 1);
                }
            } else {
                pharmacies.value = [];
                if (res.code == 404) {
                    noPharmacies.value = true;
                }
            }
        })
        .finally(() => {
            isSearching.value = false;
        });
}

watch(debouncedSearchQuery, (query) => {
    isSearchFieldDirty.value = true;

    if (query.length >= 3) {
        getPharmacies(query);
    } else if (query.length === 0) {
        pharmacies.value = [];
        router.push({ query: { ...route.query, keyword: undefined } });
        noPharmacies.value = false;
    }
});

watch(() => store.clinic.type, function (val) {
    if (val == 'clinic' && route.name == 'pharmacies') {
        clinicAvailable.value = true;
    }
});

watch(() => store.selectedPatient?.pid, function (val) {
    if (val && route.name == 'pharmacies') {
        patientAvailable.value = true;
    }
});

watch([clinicAvailable, patientAvailable], function (val) {
    if (val[0] && val[1] && route.name == 'pharmacies' && searchQuery.value.length >= 3) {
        getPharmacies(searchQuery.value);
    }
});

const shouldShowSearchHint = computed(() => isSearchFieldDirty.value && searchQuery.value.length < 3);
const shouldShowSearchLoader = debouncedRef(isSearching, 100);
</script>

<template>
    <h2 class="title">Select your Preferred Pharmacy</h2>
    <div class="mt-1 text-gray-600">Search and select the pharmacy that you want to pickup your medication after the
        appointment.</div>

    <div class="relative pt-4" ref="searchField">
        <div class="relative overflow-hidden">
            <input v-model="searchQuery" placeholder="Name, address or postal code"
                class="w-full p-2 pl-10 border rounded-xl h-13 focus:outline-none focus:border-black">
            <SearchIcon class="size-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />

            <div class="absolute top-1/2 right-3 transform -translate-y-1/2 text-foreground">
                <Loader :class="{
                    'size-5 animate-spin-slow': true,
                    'opacity-0': !shouldShowSearchLoader,
                    'opacity-100': shouldShowSearchLoader
                }" />
            </div>
        </div>
        <div
            :class="{ 'opacity-0': !shouldShowSearchHint, 'opacity-100': shouldShowSearchHint, 'text-sm text-gray-500 my-1': true}">
            Please enter <strong class="font-medium">at least 3 characters</strong></div>
    </div>

    <div v-if="store.clinic.type == 'clinic'" class="mt-2 grid gap-3">
        <Card v-for="(p, index) in pharmacies" :key="p.clinicID" class="p-3 grid gap-1 cursor-pointer"
            @click="choosePharmacy(index)">
            <div class="flex justify-between text-sm text-gray-400">
                <div>{{ p.clinicCity }}</div>
                <!-- <div>{{ p.distance }}</div> -->
            </div>
            <div class="font-semibold text-gray-900">{{ p.clinicName }}</div>
            <div v-if="p.clinicPostal || p.clinicAddress" class="text-sm text-gray-500">{{ p.clinicPostal + ' _ ' +
                p.clinicAddress }}</div>
        </Card>
        <div v-if="noPharmacies && !isSearching" class="mt-10 text-balance text-center text-gray-600">We couldn't find
            any pharmacies that match your
            search.</div>
    </div>
    <div v-else-if="store.clinic.type == 'pharmacy' && noPharmacies && !isSearching"
        class="mt-10 text-balance text-center text-gray-600">We couldn't
        find any
        pharmacies that match your search.</div>
</template>
