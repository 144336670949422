import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router/routes'
import './assets/index.css'
import './assets/iconsax-css/style.css'
import './assets/general.css'
import App from './App.vue'
import axios from 'axios'
import { configure } from 'vee-validate'
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as Sentry from "@sentry/vue";
import { trackEvent } from '@/utils/analytics'

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers['authorization'] = 'Bearer ' + token;
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app')?.scrollIntoView({ behavior: 'smooth' });
    }
});

axios.interceptors.response.use(
    (response) => {
        if (response.data?.status === 'error') {
            trackEvent('api_error', {
                event_category: 'API',
                event_label: response.config.url,
                event_value: JSON.stringify(response.data)
            });
        } else if (!response.data?.status) {
            trackEvent('api_missing_status', {
                event_category: 'API',
                event_label: response.config.url,
                event_value: JSON.stringify(response.data)
            });
        }
        return response.data
    },
    (error) => {
        trackEvent('api_request_failed', {
            event_category: 'API',
            event_label: error.config?.url,
            event_value: JSON.stringify(error)
        });
        if (error.response.status == 404) {
            router.push({name: 'NotFound' })
        }
    }
)

configure({
    validateOnChange: false,
    validateOnModelUpdate: false
})

const app = createApp(App);

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
        ],
    });
}

app.use(router).use(VueReCaptcha, {
    siteKey: import.meta.env.VITE_RECAPTCHASITEKEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
        renderParameters: {
            badge: 'bottomleft'
        },
    },
}).mount('#app');
